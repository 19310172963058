import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CartWrapper from "../components/bigcommerce/CartWrapper"

const Cart = () => {
  return (
    <Layout>
      <SEO title={"cart"} />
      <div style={{ paddingTop: 200, paddingBottom: 50 }}>
        <div className="container">
          <CartWrapper />
        </div>
      </div>
    </Layout>
  )
}

export default Cart
